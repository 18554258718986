import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import CheckCircle from "@material-ui/icons/CheckCircle"
import Box from "@material-ui/core/Box"
import { contentPaddingY } from "../config"
import { makeStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"

const services = [
  "new roofs",
  "re-roofing",
  "repairs",
  "maintenance",
  "residential",
  "commercial",
  "strata",
  "all roof styles",
  "leaf guard",
  "insulation",
  "ventilation",
  "heights and safety",
  "fully insured",
]

const useStyles = makeStyles(theme => ({
  listWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
}))

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "roof-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <Layout title="Services" bgImage={data.bgImage.childImageSharp.fluid}>
      <SEO title="Services" />

      <Box
        py={contentPaddingY}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box mb={12}>
          <Typography variant="h4" gutterBottom>
            Providing all your roofing needs
          </Typography>
          <Typography paragraph>
            At Coastline Roofing we work closely with all clients to ensure we
            can design and deliver the best solution for your roofing needs.
          </Typography>
          <Typography paragraph>
            We can help with everything from the smallest roof leak to large
            commercial projects. Coastline Roofing specialises in regular,
            ongoing property maintenance for strata and body corporate
            organisations.
          </Typography>
          <Typography paragraph>
            We strive to make sure every project is delivered with the best
            quality and the least stress possible for you.
          </Typography>
        </Box>
        <List className={classes.listWrapper}>
          {services.map(service => (
            <ListItem key={service}>
              <ListItemIcon>
                <CheckCircle color="secondary" />
              </ListItemIcon>
              <ListItemText>{service}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
    </Layout>
  )
}

export default ServicesPage
